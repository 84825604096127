import { Button, Stack, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { getBankById, getBankLedgers, getBanks, getData, saveBank, updateBank } from "../../apiservices";
import { Lay } from "../client";
import { Back } from "../../assets/Svg";
import SimpleTable from "./SimpleTable";
import PrintIcon from "@mui/icons-material/Print";
import ReactToPrint from "react-to-print";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { formatDate } from "../general";
import { useDispatch } from "react-redux";
import { handleSelectedCom } from "../../sidebar/toggleSlice";
import moment from "moment";

const Btn = styled(Button)({
  minWidth: "126px",
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#0be0b5",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
export const Typo = styled(Typography)({
  fontWeight: 700,
  fontSize: "20px",
  color: "#0be0b5",
});
export const schema = yup
  .object({
    name: yup.string().required(),
    address: yup.string().required(),
    num: yup.string().required(),
  })
  .required();

const CashComp = () => {
  const componentRef = React.useRef();
  const theme = useTheme();
  const dispatch = useDispatch();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const CustomTypo = styled(Typography)({
    fontSize: fullScreen ? "12px" : "16px",
  });
  const [state, setState] = useState({
    openModal: false,
    banksList: [],
    check: false,
    updatedData: [],
    ledgerData: [],
    bankData: {},
    viewBank: false,
    clientDataById: [],
    searchData: [],
    total_debit: 0,
    total_credit: 0,
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    // startDate: new Date(Date.now()).toISOString().split("T")[0],
    // endDate: new Date(Date.now()).toISOString().split("T")[0]
  });
  const {
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSelection = (arg) => {
    dispatch(handleSelectedCom(arg));
  };

  const handleChange = (e) => {
    let { value } = e.target;
    let { name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  // useEffect(() => {
  //   getCashReport()
  // }, [state.startDate, state.endDate]);

  useEffect(() => {
    getCashReport()
  }, []);

  const getCashReport = async () => {
    const cpDataObj = {
      table: "payments",
      filters: [
        {
          column_name: "transaction_mode",
          operation: "=",
          value: 'Cash'
        }
      ],
    }
    const cPaymentRes = await getData(cpDataObj)
    // console.log("cPaymentRes", cPaymentRes)

    const spDataObj = {
      table: "supplier_payments",
      filters: [
        {
          column_name: "transaction_mode",
          operation: "=",
          value: 'Cash'
        }
      ],
    }
    const sPaymentRes = await getData(spDataObj)
    // console.log("sPaymentRes", sPaymentRes)

    let balance = []
    // for (var d = new Date(new Date(state.startDate).toISOString().split("T")[0]); d <= new Date(new Date(state.endDate).toISOString().split("T")[0]); d.setDate(d.getDate() + 1)) {
    for (var d = new Date('2024-03-01'); d <= new Date(); d.setDate(d.getDate() + 1)) {
      if (cPaymentRes && cPaymentRes.data && cPaymentRes.data.length) {
        // console.log("d--------------------", formatDate(d));
        cPaymentRes.data.map((element) => {
          if (formatDate(element.date) == formatDate(d)) {
            balance.push({
              date: element.date,
              details: `CPayment#00${element.id}`,
              debit: element.amount,
              credit: 0
            })
            // console.log(element)
          }
        })
      }
      if (sPaymentRes && sPaymentRes.data && sPaymentRes.data.length) {
        sPaymentRes.data.map((element) => {
          if (formatDate(element.date) == formatDate(d)) {
            balance.push({
              date: element.date,
              details: `SPayment#00${element.id}`,
              debit: 0,
              credit: element.amount
            })
            // console.log(element)
          }
        })
      }
    }

    let sum_debit = 0
    let sum_credit = 0
    if (balance && balance.length) {
      for (let i = 0; i < balance.length; i++) {
        sum_debit += parseFloat(balance[i].debit)
        sum_credit += parseFloat(balance[i].credit)
        if (i == 0) {
          balance[i].closing = parseFloat(balance[i].debit) - parseFloat(balance[i].credit)
        }
        else {
          balance[i].closing = parseFloat(balance[i - 1].closing) + parseFloat(balance[i].debit) - parseFloat(balance[i].credit)
        }
      }
    }

    // console.log("balance", balance)

    setState((prev) => ({
      ...prev,
      ledgerData: balance,
      total_debit: sum_debit,
      total_credit: sum_credit
    }));
  };

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <>
      {/* <Stack
        direction={"row"}
        sx={{ padding: "10px" }}
        justifyContent={"start"}
        alignItems={"center"}
        spacing={2}>
        <Stack
          sx={{
            width: "25%",
            background: "#F6F6F6",
            borderRadius: "10px",
            boxShadow: "rgb(77, 175, 81) 0px 2px 5px",
            padding: 1,
          }}>
          <label>Start Date</label>
          <input
            style={{ padding: "10px", borderRadius: "6px" }}
            type="date"
            value={state.startDate}
            name="startDate"
            onChange={handleChange}
          />
        </Stack>
        <Typography sx={{ fontSize: "18px" }}>To</Typography>
        <Stack
          sx={{
            width: "25%",
            background: "#F6F6F6",
            borderRadius: "10px",
            boxShadow: "rgb(77, 175, 81) 0px 2px 5px",
            padding: 1,
          }}>
          <label>End Date</label>
          <input
            style={{ padding: "10px", borderRadius: "6px" }}
            type="date"
            value={state.endDate}
            name="endDate"
            onChange={handleChange}
          />
        </Stack>
      </Stack> */}
      <Stack ref={ref} id="content-to-print" sx={{ padding: 3 }}>
        <SimpleTable data={state.ledgerData} />
        <Lay
          direction={fullScreen ? "column" : "row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingRight: 2, mt: 1 }}>
          <Stack direction={"row"}></Stack>
          <Stack direction={"row"} justifyContent="end" alignItems={"end"}>
            <CustomTypo>Total Debits:</CustomTypo>
            <CustomTypo>Rs {state.total_debit}</CustomTypo>
          </Stack>
          <Stack direction={"row"} justifyContent="end" alignItems={"end"}>
            <CustomTypo>Total Credits:</CustomTypo>
            <CustomTypo>Rs {state.total_credit}</CustomTypo>
          </Stack>
          <Stack direction={"row"} justifyContent="end" alignItems={"end"}>
            <CustomTypo>Total Balance:</CustomTypo>
            <CustomTypo> {`Rs ${state.total_debit - state.total_credit}`}</CustomTypo>
          </Stack>
        </Lay>
      </Stack>
    </>
  ));
  const updateData = (data) => {
    setState((prev) => ({ ...prev, banksList: data }));
  };
  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <Stack>
        <Layout
          justifyContent="end"
          alignItems={"center"}
          direction={"row"}>
          <Btn onClick={() => handleSelection("dashboard")}>
            <Back sx={{ mx: 1 }} />
            {!fullScreen && "Back"}
          </Btn>

          <ReactToPrint
            trigger={() => (
              <Btn>
                <PrintIcon fontSize="small" sx={{ mx: 1 }} />{" "}
                {!fullScreen && "Print"}
              </Btn>
            )}
            content={() => componentRef.current}
          />
        </Layout>
        <ComponentToPrint ref={componentRef} />
      </Stack>
    </Stack>
  );
};

export default CashComp;
