import React, { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import SaleTable from "./SaleTable";
import PaymentTable from "./PaymentTable";
import { getPaymentsBetweenDates, getSaleBetweenDates } from "../../apiservices";
import { Layout } from "../dashboard/dashboard";
import { Btn } from "../payment";
import { Back } from "../../assets/Svg";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactToPrint from "react-to-print";
import { handleSelectedCom } from "../../sidebar/toggleSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
function TransactionComp() {
  const componentRef = React.useRef();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    saleTotal: 0,
    paymentTotal: 0,
    saleData: [],
    paymentData: [],
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const getBillData = async () => {
    let res = await getSaleBetweenDates(state.startDate.toString(), state.endDate.toString());
    // console.log("SaleRes", res)
    if (res.success) {
      let sum = 0
      res.data.map(x => {
        sum += parseFloat(x.amount)
      })
      setState((prev) => ({
        ...prev,
        saleData: res.data,
        saleTotal: sum
      }));
    }
  };

  const getPayments = async () => {
    let res = await getPaymentsBetweenDates(state.startDate.toString(), state.endDate.toString());
    // console.log("paymentRes", res)
    if (res.success) {
      let sum = 0
      res.data.map(x => {
        sum += parseFloat(x.amount)
      })
      setState((prev) => ({
        ...prev,
        paymentData: res.data,
        paymentTotal: sum
      }));
    }
  };

  useEffect(() => {
    getBillData();
    getPayments();
  }, [state.startDate, state.endDate]);
  const handleSelection = (arg) => {
    dispatch(handleSelectedCom(arg));
  };
  const handleChange = (e) => {
    let { value } = e.target;
    let { name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <>
      <Stack
        direction={"row"}
        sx={{ padding: "10px" }}
        justifyContent={"start"}
        alignItems={"center"}
        spacing={2}>
        <Stack
          sx={{
            width: "25%",
            background: "#F6F6F6",
            borderRadius: "10px",
            boxShadow: "rgb(77, 175, 81) 0px 2px 5px",
            padding: 1,
          }}>
          <label>Start Date</label>
          <input
            style={{ padding: "10px", borderRadius: "6px" }}
            type="date"
            value={state.startDate}
            name="startDate"
            onChange={handleChange}
          />
        </Stack>
        <Typography sx={{ fontSize: "18px" }}>To</Typography>
        <Stack
          sx={{
            width: "25%",
            background: "#F6F6F6",
            borderRadius: "10px",
            boxShadow: "rgb(77, 175, 81) 0px 2px 5px",
            padding: 1,
          }}>
          <label>End Date</label>
          <input
            style={{ padding: "10px", borderRadius: "6px" }}
            type="date"
            value={state.endDate}
            name="endDate"
            onChange={handleChange}
          />
        </Stack>
      </Stack>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        ref={ref}
        id="content-to-print">
        <Grid
          container
          sx={{ mt: 3, display: "flex", justifyContent: "center" }}
          spacing={2}>
          <Grid item={6}>
            <Stack
              justifyContent={"space-around"}
              alignItems={"center"}
              direction={"row"}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 1 }}>
                Sales
              </Typography>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 1 }}>
                Total : {state.saleTotal}
              </Typography>
            </Stack>
            <SaleTable data={state.saleData} />
          </Grid>
          <Grid item={6}>
            <Stack
              justifyContent={"space-around"}
              alignItems={"center"}
              direction={"row"}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 1 }}>
                Payments
              </Typography>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 1 }}>
                Total : {state.paymentTotal}
              </Typography>
            </Stack>
            <PaymentTable data={state.paymentData} />
          </Grid>
        </Grid>
      </Stack>
    </>
  ));
  return (
    <>
      <Layout
        justifyContent="space-between"
        alignItems={"center"}
        direction={"row"}>
        <Typography></Typography>
        <Typography
          sx={{
            color: "#000",
            fontSize: "24px",
            fontWeight: 700,
            padding: 1,
          }}>
          Transactions
        </Typography>
        <Stack direction={"row"}>
          {/* <ReactToPrint
            trigger={() => (
              <Btn>
                <PictureAsPdfIcon fontSize="small" sx={{ mx: 1 }} /> PDF
              </Btn>
            )}
            content={() => componentRef.current}
          /> */}

          <ReactToPrint
            trigger={() => (
              <Btn>
                <PrintIcon fontSize="small" sx={{ mx: 1 }} /> Print
              </Btn>
            )}
            content={() => componentRef.current}
          />
          <Btn onClick={() => handleSelection("dashboard")}>
            <Back sx={{ mx: 1 }} /> Back
          </Btn>
        </Stack>
      </Layout>
      <ComponentToPrint ref={componentRef} />
    </>
  );
}

export default TransactionComp;
