import { Stack, Typography } from "@mui/material"
import Logo from "../../assets/erorex-Logo.png";

const ClientInfo = () => {
  return (
    <Stack
      sx={{ mb: 2, mt: 1 }}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"end"}
      spacing={3}>
      <img src={Logo} alt="logo" width={"450"} height={"120"} />

      <Stack sx={{mt: "-10px"}}>
        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
          Insaf Communication
        </Typography>
        <Typography sx={{ fontSize: "11px" }}>
          Shop No 63, Sarhad Plaza Liaquat Road,
        </Typography>
        <Typography sx={{ fontSize: "11px" }}>
          Raja Bazar, Rawalpindi
        </Typography>
        <Typography sx={{ fontSize: "11px" }}>
          Abdul Rehman : 0346-6838338
        </Typography>
        <Typography sx={{ fontSize: "11px" }}>
          Usama Khan   : 0312-5990398
        </Typography>
      </Stack>
    </Stack>
  )
}

export default ClientInfo