import styled from "@emotion/styled";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CancelIcon from "@mui/icons-material/Cancel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Btn } from ".";
import { loginAdmin, loginUser } from "../../apiservices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export const CustomFormControl = styled(FormControl)({
  m: 1,
  background: "#FFF",
  padding: "2px",
  borderRadius: "4px",
});
export const schema = yup
  .object({
    email: yup.string().required(),
    password: yup.string().required(),
  })
  .required();
function AdminLogin({ loginType, changeType }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [state, setState] = useState({
    createField: "",
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleCancel = () => {
    setValue("email", "");
  };

  const adminLogin = async (data) => {
    let credentials = {
      username: data.email,
      password: data.password,
    };
    let res = await loginAdmin(credentials);
    console.log(res)
    if (res.success) {
      const {password, ...dataObj} = res.data[0]
      localStorage.setItem("user", JSON.stringify(dataObj));
      localStorage.setItem("loggedIn", true);
      navigate("/dashboard");
      toast.success("Login Successfully");
    }
    else{
      toast.error("Wrong Credentials");
    }
    if (res.user) {
      localStorage.setItem("loggedIn", true);
      navigate("/dashboard");
    }
  };

  const submitForm = () => {
    // loginType == "admin" ? 
    handleSubmit(adminLogin)()
    // : handleSubmit(userLogin)();
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <Stack justifyContent="center" sx={{ width: "75%" }}>
      {" "}
      <CustomFormControl sx={{ mt: 5 }}>
        <InputLabel htmlFor="standard-adornment-password">
          {"Username"}
        </InputLabel>
        <OutlinedInput
          id="standard-adornment-password"
          type="text"
          label={"Username"}
          {...register("email")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleCancel}>
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <Typography sx={{ color: "red" }}>{errors.email?.message}</Typography>
      </CustomFormControl>
      <CustomFormControl sx={{ mt: 3 }}>
        <InputLabel htmlFor="standard-adornment-password">
          {" "}
          {"Password"}
        </InputLabel>
        <OutlinedInput
          id="standard-adornment-password"
          type={showPassword ? "text" : "password"}
          {...register("password")}
          label={"Password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Typography sx={{ color: "red" }}>
          {errors.password?.message}
        </Typography>
      </CustomFormControl>
      <Stack alignItems="center" justifyContent="center">
        <Btn
          onClick={submitForm}
          sx={{
            background: "#0be0b5",
            color: "#000",
            width: "40%",
            mt: 4,
            "&:hover": { background: "#61D140" },
            "&:active": { background: "#0be0b5" },
          }}>
          {" "}
          Login
        </Btn>
        {/* {state.createField !== "4101294" ? (
          <Stack sx={{ m: 2 }}>
            <TextField
              label="Enter code to create account"
              name="createField"
              size="small"
              value={state.createField}
              onChange={handleChange}
            />
          </Stack>
        ) : (
          <Button
            onClick={() => changeType("regType", "reg")}
            sx={{
              fontWeight: 600,
              color: "#0be0b5",
              fontSize: "16px",
              textTransform: "none",
              mt: 2,
            }}>
            {" "}
            Create an account
          </Button>
        )} */}
        {/* <Typography sx={{ color: "#9F9E9E" }}>
          Forgot password ?
          <Button
            sx={{
              textTransform: "none",
              color: "#0be0b5",
              fontWeight: 600,
            }}>
            Reset
          </Button>{" "}
        </Typography> */}
      </Stack>
    </Stack>
  );
}

export default AdminLogin;
