import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { deleteCountry, deleteProvider } from "../../apiservices";
import { toast } from "react-toastify";
const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#0be0b5",
  "&:hover": {
    color: "#000",
    background: "#0be0b5",
  },
});

export default function DataTable({ data, editCountry, getData, handleCheck }) {
  const columns = [
    { field: "country_name", headerName: "Country Name", width: 250 },
    { field: "province_name", headerName: "Province Name", width: 250 },
    { field: "city_name", headerName: "City Name", width: 150 },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <>
          <Btn
            title="Edit"
            variant="contained"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#eed202", color: "white" }}
            onClick={() => edit(params.row)}>
            <EditIcon fontSize="small" />
          </Btn>
          <Btn
            title="Delete"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "red", color: "white" }}
            variant="contained"
            onClick={() => deleteRow(params.row)}>
            <DeleteIcon fontSize="small" />
          </Btn>
        </>
      ),
    },
  ];

  const rows = data;
  const edit = (params) => {
    editCountry(params);
    handleCheck();
  };
  const deleteRow = async (params) => {
    /* eslint-disable no-restricted-globals */
    if(confirm(`Are you sure to delete "${params.city_name}"`)){
      let res = await deleteCountry(params.id);
      if (res) {
        toast.error("City deleted successfully");
        getData();
      }
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        // checkboxSelection
      />
    </div>
  );
}
